import { components } from './pageComponents'

const generateRouterTree = ( routerEntries ) => {

  if( !routerEntries ) {
    return null
  }

  return routerEntries
    .map( ( entry ) => {
      const [
        title,
        ,
        subLinks,
      ] = entry
      let [
        ,
        link,
        ,
        componentName,
      ] = entry

      // if no link is given, defaults to kebab case of the title
      // If you are wondering about the operator on the line below,
      // check here: https://mzl.la/2OqxUBi
      link = link ?? title.split( ' ' )
        .join( '-' )
        .toLowerCase()

      // if no component name is given, defaults to PascalCase of the link
      // If you are wondering about the operator on the line below,
      // check here: https://mzl.la/2OqxUBi
      componentName = componentName ?? link.split( '-' )
        .map( ( chunk ) => chunk.charAt( 0 )
          .toUpperCase()
    + chunk.slice( 1 ),
        )
        .join( '' )

      // warning: recursion ahead
      return {
        component: components[componentName],
        componentName,
        link,
        subLinks: generateRouterTree( subLinks ),
        title,
      }

    } )
}
// ----- CONFIGURE YOUR ROUTES HERE -----
// add new page components in ./pageComponents.js
/** route entry blueprint
 * [
 * title
 * link
 * sublinks
 * componentName
 * ]
 */
const router = generateRouterTree( [
  [ 'Dashboard' ],
  [ 'Companies' ],
  [ 'Coaches' ],
  [ 'Candidates' ],
  [ 'Zoom Events' ],
  [
    'Program Manager',
    'program-manager',
    [
      [ 'Milestones' ],
      [ 'Programs' ],
      [ 'Program Templates' ],
    ],
  ],
  [ 'Upload Manager' ],
  [ 'Referral Firms' ],
  [ 'Email Manager' ],
  [ 'Announcements' ],
  [
    'Accounting',
    'accounting',
    [
      [ 'Billing Data Extract' ],
      [ 'Exports' ],
      [ 'Billing Invoice Data Import' ],
    ],
  ],
  [ 'Reports',
    'reports',
    [
      [ 'Configurable Reports' ],
      [ 'Prebuilt Reports' ],
    ],
  ],
  [ 'Survey Manager' ],
  [
    'System Key/Values',
    'system-key-values',
  ],
  [ 'Admin Users' ],
  [ 'My Hours' ],
  [ 'Candidates Referred' ],
  [ 'Candidates Engaged' ],
] )

export const getRouterNestedSwitchRouteEntries = (
  path,
  routerSlice = router,
) => {

  const pathEntries = path.replace( /^\//, '' ).split( '/' )
  const branch = pathEntries.shift()

  routerSlice = routerSlice
    .find( ( routeEntry ) => routeEntry.link === branch )?.subLinks

  if( 0 === pathEntries.length ) {
    return routerSlice
  }

  // recursion
  return getRouterNestedSwitchRouteEntries(
    pathEntries.join( '/' ),
    routerSlice )
}

export default router