import axios from 'axios'
import Cookie from 'js-cookie'

const config = {
  headers: { Authorization: Cookie.get( 'Authorization' ) },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
}

const httpClient = axios.create( config )

httpClient.interceptors.response.use(
  ( response ) => response,
  ( error ) => {
    if (
      401 === error.response.status
      || ( 403 === error.response.status
        && 'Invalid token.' === error.response.data.detail )
    ) {
      httpClient.defaults.headers['Authorization'] = ''
      Cookie.remove( 'Authorization' )
    }
    return Promise.reject( error.response )
  },
)

export default httpClient
