import React, { createContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

import useApi from '../useApi'
import { useLocation, useHistory } from 'react-router-dom'

const AuthContext = createContext( {} )

export const AuthContextProvider = ( { children } ) => {

  const authCookie = Cookies.get( 'Authorization' )
  const location = useLocation()
  const history = useHistory()

  const [ userData, setUserData ] = useState( null )
  const [ fetchUserData, setFetchUserData ] = useState( false )

  const { data, refetch } = useApi( 'get', '/api/profile/', null, {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
  } )

  const isAdmin = () => userData?.group?.name.match( /Admin$/ )
  const isClient = () => userData?.group?.name.match( /Client$/ )
  const isCoach = () => userData?.group?.name.match( /Coach$/ )
  const isCandidate = () => userData?.group?.name.match( /Candidate$/ )
  const isLoggedIn = () => undefined !== authCookie

  useEffect( () => {
    // this location check is a hackaround for now, till we find a way to log out candidate
    // for candidate getting redirected to docebo
    if ( '/login' !== location.pathname && ( true === fetchUserData || !!authCookie ) ) {
      refetch()
    }
  }, [ fetchUserData, refetch, authCookie, location ] )

  useEffect( () => {
    if ( data ){
      if ( isCandidate() ) {
        if ( !data.docebo_sso_link ) {
          alert( 'Failed to get SSO link. Please ensure this user in the learning system and is active.' )
        } else {
          history.push( '/calendar/' )
        }
      } else {
        setUserData( data )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ data ] )

  const isActionAuthorized = ( action ) =>
    userData?.group?.sections?.find( ( { name } ) => name === action )?.permission
    ?? false


  return (
    <AuthContext.Provider
      value={ {
        isActionAuthorized,
        isAdmin,
        isCandidate,
        isClient,
        isCoach,
        isLoggedIn,
        refreshUser: refetch,
        setFetchUserData,
        setUserData,
        user: userData,
      } }
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
