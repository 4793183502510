import { lazy } from 'react'

const Accounting = lazy( () => import( '../../pages/Accounting/' ) )
const AdminUsers = lazy( () => import( '../../pages/AdminUsers' ) )
const Announcements = lazy( () => import( '../../pages/Announcements' ) )
const BillingDataExtract = lazy( () => import( '../../pages/Accounting/BillingDataExtract' ) )
const Exports = lazy( () => import( '../../pages/Accounting/Exports' ) )
const BillingInvoiceDataImport = lazy( () => import( '../../pages/Accounting/BillingInvoiceDataImport' ) )
const Candidates = lazy( () => import( '../../pages/Candidates' ) )
const CandidatesEngaged = lazy( () => import( '../../pages/CandidatesEngaged' ) )
const CandidatesReferred = lazy( () => import( '../../pages/CandidatesReferred' ) )
const Coaches = lazy( () => import( '../../pages/Coaches' ) )
const Companies = lazy( () => import( '../../pages/Companies' ) )
const Dashboard = lazy( () => import( '../../pages/Dashboard' ) )
const EmailManager = lazy( () => import( '../../pages/EmailManager' ) )
const Milestones = lazy( () => import( '../../pages/ProgramManager/Milestones' ) )
const MyHours = lazy( () => import( '../../pages/MyHours' ) )
const ProgramManager = lazy( () => import( '../../pages/ProgramManager' ) )
const ProgramTemplates = lazy( () => import( '../../pages/ProgramManager/ProgramTemplates' ) )
const Programs = lazy( () => import( '../../pages/ProgramManager/Programs' ) )
const ReferralFirms = lazy( () => import( '../../pages/ReferralFirms' ) )
const Reports = lazy( () => import( '../../pages/Reports' ) )
const ConfigurableReports = lazy( () => import( '../../pages/Reports/ConfigurableReports' ) )
const PrebuiltReports = lazy( () => import( '../../pages/Reports/PrebuiltReports' ) )
const SurveyManager = lazy( () => import( '../../pages/SurveyManager' ) )
const SystemKeyValues = lazy( () => import( '../../pages/SystemKeyValues' ) )
const UploadManager = lazy( () => import( '../../pages/UploadManager' ) )
const ZoomEvents = lazy( () => import( '../../pages/ZoomEvents' ) )

export const components = {
  Accounting,
  AdminUsers,
  Announcements,
  BillingDataExtract,
  BillingInvoiceDataImport,
  Candidates,
  CandidatesEngaged,
  CandidatesReferred,
  Coaches,
  Companies,
  ConfigurableReports,
  Dashboard,
  EmailManager,
  Exports,
  Milestones,
  MyHours,
  PrebuiltReports,
  ProgramManager,
  ProgramTemplates,
  Programs,
  ReferralFirms,
  Reports,
  SurveyManager,
  SystemKeyValues,
  UploadManager,
  ZoomEvents,
}
