import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
// eslint-disable-next-line no-unused-vars
// import { ReactQueryDevtools } from 'react-query/devtools'

import './App.scss'

import router from './common/router'

import { AuthContextProvider } from './common/auth'
// import { UiContextProvider } from './common/UiContext'

const Login = lazy( () => import( './pages/Login' ) )
const ChangePassword = lazy( () => import( './pages/ChangePassword' ) )
const Calendar = lazy( () => import( './pages/Calendar' ) )
const ProtectedRoute = lazy( () => import( './components/ProtectedRoute/ProtectedRoute' ) )
const Title = lazy( () => import( './components/Title' ) )
const NotFound = lazy( () => import( './pages/404' ) )

const UiContextProvider = lazy( () => import( './common/UiContext' )
  .then( ( module ) => ( { default: module.UiContextProvider } ) ) )


const App = () => {
  const queryClient = new QueryClient( { defaultOptions: { queries: { refetchOnWindowFocus: false } } } )

  return (
    <Suspense fallback=''>
      <HelmetProvider>
        <QueryClientProvider client={ queryClient }>
          <Router>
            <AuthContextProvider>
              <UiContextProvider>
                <Title />
                <Switch>
                  <Route
                    path='/login'
                    component={ Login }
                  />
                  <Route
                    path='/change-password/:uid/:token'
                    component={ ChangePassword }
                  />

                  <ProtectedRoute
                    path='/'
                    exact={ true }
                  >
                    <Redirect to='/dashboard' />
                  </ProtectedRoute>
                  <ProtectedRoute
                    path='/calendar'
                    component={ Calendar }
                  />
                  {
                    router.map( ( routeEntry ) =>
                      <ProtectedRoute
                        key={ routeEntry.link }
                        path={ `/${ routeEntry.link }` }
                        component={ routeEntry.component }
                      />,
                    )
                  }
                  <ProtectedRoute path='*'><NotFound /></ProtectedRoute>
                </Switch>
              </UiContextProvider>
              {/* <ReactQueryDevtools initialIsOpen={ false } position='bottom-right' /> */}
            </AuthContextProvider>
          </Router>
        </QueryClientProvider>
      </HelmetProvider>
    </Suspense>
  )
}

export default App
