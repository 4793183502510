import Get from './get'
import Put from './put'
import Patch from './patch'

const update = ( endpoint, params, config ) => {

  const get = Get( endpoint, params, config )
  const put = Put( endpoint, params )
  const patch = Patch( endpoint, params )

  return {
    get,
    patch,
    put,
  }
}

export default update