import { useQuery } from 'react-query'
import httpClient from './httpClient'

const getQueryFunction = ( { queryKey: [ endpoint, id ] } ) => new Promise( ( resolve, reject ) => {
  endpoint = id ? `${ endpoint }/${ id }/` : endpoint
  httpClient.get( endpoint )
    .then( ( { data } ) => {
      resolve( data )
    } )
    .catch( ( e ) => {
      reject( e )
    } )
} )

const Get = ( endpoint, params, config ) => useQuery( [ endpoint, params ], getQueryFunction, config )

export default Get