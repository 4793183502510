import {
  useMutation,
  useQueryClient,
} from 'react-query'
import httpClient from './httpClient'

const Patch = ( endpoint, params ) => {
  const queryClient = useQueryClient()

  const patchQueryFunction = ( { id, ...params } ) => new Promise( ( resolve, reject ) => {
    httpClient.patch( `${ endpoint }/${ id }/`, { ...params } )
      .then( ( { data } ) => {
        resolve( data )
      } )
      .catch( ( e ) => {
        reject( e )
      } )
  } )

  return useMutation( patchQueryFunction, {
    // Refetch data after mutation
    onSettled: () => {
      queryClient.invalidateQueries( endpoint )
    },
  } )
}

export default Patch