import { useLocation } from 'react-router-dom'
import router from './router'

export const usePageInfo = () => {
  const location = useLocation()
  const pathEntries = location.pathname.replace( /^\//, '' ).split( '/' )

  const getInfoFromPathEntries = ( entries, routes = router, icon, parents = [] ) => {
    if( '/dashboard' === location.pathname ) {
      return {
        icon: 'Dashboard',
        title: 'Dashboard',
      }
    }
    if( '/login' === location.pathname ) {
      return { title: 'Login' }
    }
    const locationEntry = entries.shift()
    const currentEntry = routes.find( ( entry ) => entry.link === locationEntry )

    if( undefined === currentEntry ){
      return {
        icon: 'NotFound',
        title: 'Page not found',
      }
    }

    // Adding first icon
    icon = icon || currentEntry.componentName

    // Recursion ahead
    return null === currentEntry.subLinks || 0 === entries.length
      ? {
        icon,
        parents,
        title: currentEntry.title,
      }
      : getInfoFromPathEntries( entries, currentEntry.subLinks, icon, [
        ...parents,
        {
          link: currentEntry.link,
          title: currentEntry.title,
        },
      ] )
  }

  return getInfoFromPathEntries( pathEntries )
}
